export default {
  heading: "Frequently Asked Questions",
  list: [
    {
      title: "How to apply for loan?",
      html: `Simply decide how much you want to apply for and fill in the short online form. We'll search for a lender for you, and if approved, a loan agreement will be sent to you online. In some cases, your lender may need some verification of details such as employer or salary, but these can usually be sent online. If you agree to the lender's offer, the money will be transferred to your account, often on the same day. Once you get paid, you repay the loan and interest.`,
    },
    {
      title: "How much can I borrow?",
      html: `The exact amount you can borrow depends on your personal circumstances and can differ by lender but loans are generally from £100 to £5,000.`,
    },
    {
      title: "What can I use the loan for?",
      html: `There are not generally any restrictions on what you can use the money for, however little-loans are designed as a short term loan for emergencies and should not be used frivolously.`,
    },
    {
      title: "When will the money be in my account?",
      html: `Once accepted, the money could be sent to your bank account in as little as 15 minutes, however some lenders may include an extra charge for this service. Most loans are paid into your account on the same day and without any transaction charges. The time that it takes for the cash to be received in your account will depend on your bank’s policies and procedures.`,
    },
    {
      title: "What is the criteria for Loans?",
      html: `If you are over 18, receiving a regular income of £750 per month or more you are likely to meet the basic criteria. You will need a bank account with a valid debit card, be a UK resident and the lender must be able to prove your identity.`,
    },
    {
      title: "Can I apply for more than one loan?",
      html: `We would not recommend you doing so. Lenders will require that you pay off your existing loan before approving you for a new loan. You should only borrow what you can afford to pay back on your next pay date to avoid further interest and charges and to avoid damaging your credit rating.`,
    },
    {
      title: "Do you use credit scoring or credit reference agencies?",
      html: `Lenders will assess your application in a variety of ways. This may include checking with credit reference and fraud prevention agencies. They will also assess your ability to repay the loan before making a decision on your application.`,
    },
    {
      title: "Why is the APR range offered so wide?",
      html: `We work with a vast range of lenders to enable customers with all range of circumstances to be able to find a loan, so our APRs have a large range - our prime lenders offers very low APRs from 9.3% APR and other lenders offer loans over shorter periods to our customers with bad credit ratings, which represent the upper end of our scale as the APRs are generally higher on these products.`,
    },
    {
      title: "When will I need to repay the loan?",
      html: `Terms and conditions vary, however when you will be required to repay your loan will depend on which product you apply for. Full details of when your repayments will start, how often and when repayments will need to be made, the term and for how long the repayments need to be made will be explained on the loan agreement provided to you.`,
    },
    {
      title: "How do I repay the loan?",
      html: `The payment is taken automatically using the debit card details you provided so it is essential that you have the required funds available to avoid further interest or late payment charges.`,
    },
    {
      title: "What is Continuous Payment Authority?",
      html: `Some lenders use CPA (continuous payment authority) to retrieve payment. This means that you authorise the lender to withdraw the funds from your bank account. They can also make multiple attempts to withdraw the money if it is not available. If you want to cancel your CPA, you can do this by contacting the lender or by contacting your bank and requesting a cancellation.`,
    },
    {
      title: "Is the information I submit when applying for a loan secure?",
      html: `Yes. All your personal data is kept secure and confidential and we only keep it for as long as necessary. All the information you provide is stored in compliance with GDPR 2016 and kept secure in accordance with our privacy policy.`,
    },
    {
      title: "I don’t have a debit card - can I still apply?",
      html: `It is a requirement that applicants have a debit card.`,
    },
  ],
}
