import React, { ReactElement } from "react"
import PageContainer from "../hoc/page"
import DefaultLayout from "../layouts/default"
import Data from "../data/faq"
import "./style.css"
import HelmetContainer from "../hoc/helmet"
function Index({}: any): ReactElement {
  return (
    <>
      <DefaultLayout>
        <HelmetContainer pageTitle="Häufige Fragen"/>
        <PageContainer Data={Data} />
      </DefaultLayout>
    </>
  )
}

export default Index
